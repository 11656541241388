import { i18n } from "@/plugins/i18n";
export default [
  {
    text: i18n.t("marital_status.married"),
    value: "MARRIED"
  },
  {
    text: i18n.t("marital_status.single"),
    value: "SINGLE"
  },
  {
    text: i18n.t("marital_status.divorced"),
    value: "DIVORCED"
  }
];
